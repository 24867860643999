import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import InnerPageLayout from "../components/inner-page-layout"

export default function KitInformation() {
  return ( 
    <Layout>
        <section className="container">
        <h1>Kit Information</h1>
        <InnerPageLayout>
            <p>Our current season matchwear and training kits along with sizing information are shown below. For more information about our kits please download our <a target="_blank" href="downloads/leeds city proposal 2020.pdf">brochure</a> and for further information about sizing <a target="_blank" href="https://issuu.com/17sport/docs/kappa_catalogue_low_res">click here</a>. For kit enquiries or more information please email <a href="mailto:leedscityjnrs@gmail.com">Mike Inman</a> or use the <Link to="/contact">contact page</Link>.</p>
            <img src="kit-matchwear.jpg" className="img=fluid object-fit-cover mb-4" />
            <img src="kit-trainingwear2.jpg" className="img=fluid object-fit-cover mb-4" />
            <img src="kit-trainingwear.jpg" className="img=fluid object-fit-cover mb-4" />
            <img src="kit-sizing.jpg" className="img=fluid object-fit-cover mb-4" />
        </InnerPageLayout>
      </section>
    </Layout>
  );
}
